.projects-outer-container {
  display: flex;
  justify-content: center;
  .projects-container {
    display: flex;
    flex-direction: column;
    padding: 50px 0px 20px;
    gap: 50px;
    max-width: 1920px;
    overflow: hidden;
    background-color: rgb(255, 255, 255, 1);
    .project-highlights-text {
      font-family: "Canela-Regular", sans-serif;
      font-size: 40px;
      font-weight: 500;
      line-height: 44px;
      text-align: center;
      color: #486284;
      width: 80%;
      margin: auto;
    }
    .office-gallery-carousal-section {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: calc(100vw - 40px);
      max-width: 100vw;
      overflow-x: hidden;
      margin: auto;
      padding-bottom: 70px;
      .office-image-carousal,
      .office-single-carousal {
        width: 110%;
        .carousal-slider {
          .slick-slider {
            .slick-list {
              overflow: visible !important;
              .slick-track {
                .slick-slide {
                  filter: opacity(1);
                }
                .slick-active {
                  padding-right: 0px;
                  filter: opacity(1);
                  transition: 0.3s all ease-out;
                }
                // .slick-active + div {
                //   height: 400px;
                //   transition: 0.3s all ease-out;
                //   .office-image-container {
                //     height: 400px;
                //     transition: 0.3s all ease-out;
                //     .office-item-image {
                //       height: 400px;
                //       transition: 0.3s all ease-out;
                //     }
                //   }
                // }
              }
            }
          }
          .slick-arrow::before {
            display: none;
          }

          .slick-arrow {
            display: none;
            z-index: 4 !important;
            background: transparent;
            height: 40px;
            width: 40px;
            transform: scale(0.8);
            top: 40%;
            svg {
              g {
                path {
                  fill: #b88e2f !important;
                }
              }
            }
          }

          .slick-prev {
            left: 0%;
          }

          .slick-next {
            right: 0%;
          }

          .office-image-container {
            height: 460px;
            transition: 0.3s all ease-out;
            position: relative;
            pointer-events: none;
            .office-item-image {
              height: 460px;
              transition: 0.3s all ease-out;
              width: 90%;
              object-fit: cover;
              border-radius: 20px;
            }
            .image-view-text {
              position: absolute;
              bottom: 18px;
              left: 18px;
              width: fit-content;
              background-color: rgba(255, 255, 255, 0.72);
              backdrop-filter: blur(1.5px);
              padding: 8px 16px;
              font-family: "Mallory-Book", serif;
              font-size: 16px;
              text-align: left;
              color: #3a3a3a;
              max-width: 60%;
            }
          }
          .slick-dots {
            display: flex !important;
            justify-content: center;
            position: absolute;
            left: 46%;
            transform: translateX(-50%);
            bottom: -50px;
            li {
              background-color: rgba(255, 255, 255, 0.5);
              width: 6px;
              height: 6px;
              border-radius: 50%;
              margin: 0px 3px;
              button::before {
                display: none;
              }
            }
            .slick-active {
              background-color: var(--tango);
              width: 20px;
              height: 6px;
              border-radius: 30px;
            }
            li {
              background-color: rgba(0, 0, 0, 0.5);
            }
          }
        }
      }
      .office-single-carousal {
        width: 100%;
        .carousal-slider {
          .slick-slider {
            .slick-list {
              .slick-active {
                padding-inline: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .projects-outer-container {
    .projects-container {
      padding-bottom: 30px;
      .office-gallery-carousal-section {
        overflow: hidden;
        width: calc(100vw - 40px);
        margin: auto;
        justify-content: flex-start;
        .office-image-carousal,
        .office-single-carousal {
          width: 80%;
          .carousal-slider {
            .office-image-container {
              width: 100%;
              height: 450px;
              .image-view-text {
                padding: 16px 24px !important;
              }
            }
            .slick-slider {
              .slick-list {
                overflow: visible !important;
                .slick-track {
                  // .slick-slide {
                  // }
                  .slick-active {
                    padding-right: 0px;
                    .office-image-container {
                      .office-item-image {
                        width: 98%;
                      }
                    }
                  }
                }
              }
            }
            .slick-arrow {
              display: none;
              height: 50px;
              width: 50px;
            }
            .slick-next {
              right: -21%;
            }

            .slick-prev {
              left: 1%;
            }
            .slick-dots {
              width: fit-content;
              left: 58%;
              transform: translateX(0%);
              bottom: -50px;
            }
          }
        }
        .office-single-carousal {
          width: 100%;
          .carousal-slider {
            .slick-slider {
              .slick-list {
                .slick-active {
                  padding-inline: 0px !important;
                }
              }
            }
            .slick-dots {
              display: none !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .projects-outer-container {
    .projects-container {
      .office-gallery-carousal-section {
        width: calc(100vw - 60px);
        max-width: 1920px;
        margin: auto;
        justify-content: flex-start;
        .office-image-carousal,
        .office-single-carousal {
          width: 780px;
          max-width: 1920px;
          .carousal-slider {
            .slick-slider {
              .slick-list {
                .slick-track {
                  // .slick-active ~ div {
                  //   transition-delay: 0.5s;
                  //   width: 372px !important;
                  //   height: 490px;
                  //   .office-image-container {
                  //     height: 490px;
                  //     transition: 0.3s transform ease-out;
                  //     .office-item-image {
                  //       height: 490px;
                  //       transition: 0.3s transform ease-out;
                  //     }
                  //   }
                  // }
                  // .slick-active {
                  .office-image-container {
                    .office-item-image {
                      width: 98%;
                      height: 590px;
                    }
                  }
                  // }
                }
              }
            }
            .office-image-container {
              width: 100%;
              height: 590px;
            }
            .slick-next {
              right: auto;
              left: calc(100vw - 130px);
            }
            .slick-prev {
              left: 1%;
            }
            .slick-arrow {
              display: block;
              top: 45%;
              height: 70px;
              width: 70px;
            }
            .slick-dots {
              position: absolute;
              left: 50vw;
              transform: translateX(-80%);
              bottom: -40px;
            }
          }
        }
        .office-single-carousal {
          width: 100%;
          .carousal-slider {
            .slick-slider {
              .slick-list {
                .slick-active {
                  padding-inline: 0px !important;
                }
              }
            }
            .slick-dots {
              display: none !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .projects-outer-container {
    .projects-container {
      .office-gallery-carousal-section {
        width: calc(100vw - 60px);
        max-width: 1920px;
        margin: auto;
        justify-content: flex-start;
        .office-image-carousal,
        .office-single-carousal {
          width: 65%;
          .carousal-slider {
            .slick-next {
              right: auto;
              left: 91vw;
            }
            // .slick-dots {
            //   left: 107%;
            // }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .projects-outer-container {
    .projects-container {
      .office-gallery-carousal-section {
        width: calc(100vw - 60px);
        max-width: 1920px;
        margin: auto;
        justify-content: flex-start;
        .office-image-carousal,
        .office-single-carousal {
          width: 69%;
          .carousal-slider {
            .slick-next {
              right: auto;
              left: 91vw;
            }
            // .slick-dots {
            //   left: 106%;
            // }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .projects-outer-container {
    .projects-container {
      max-width: 1580px;
      .office-gallery-carousal-section {
        width: calc(100vw - 60px);
        max-width: 1920px;
        margin: auto;
        justify-content: flex-start;
        .office-image-carousal,
        .office-single-carousal {
          width: 60%;
          .carousal-slider {
            .slick-next {
              right: auto;
              left: 1500px;
            }
            .slick-dots {
              left: 70%;
            }
          }
        }
      }
    }
  }
}
