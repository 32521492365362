.card-container {
  width: 335px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden; // Prevents content from overflowing out of the container
  background-color: rgba(255, 255, 255, 1);
  .image-carousel {
    pointer-events: none;
    width: 335px;
    height: 340px;
    overflow: hidden; // Ensures the carousel doesn’t overflow the container
    .office-image-container {
      pointer-events: none;
      .office-item-image {
        width: 250%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .carousal-slider {
      position: relative;

      .slick-arrow::before {
        display: none;
      }

      .slick-arrow {
        z-index: 4 !important;
        background: transparent;
        height: 40px;
        width: 40px;
      }

      .slick-prev {
        left: 5px;
      }

      .slick-next {
        right: 5px;
      }
    }
  }

  .card-content {
    width: 295px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    min-height: 210px;
    .date {
      font-family: "Canela-Bold", sans-serif;
      width: fit-content;
      height: 20.42px;
      color: #24272c;
      text-align: left;
    }

    .description {
      width: 100%;
      font-family: "Mallory-Book", sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      color: #5e6282;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      height: auto;
    }

    .expanded-description {
      cursor: pointer;
      display: block;
      width: auto;
      height: auto;
      font-family: "Mallory-Book", sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      color: #5e6282;
    }

    .read-more {
      cursor: pointer;
      width: fit-content;
      height: 30px;
      font-family: "Mallory-Book", sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      color: #5e6282;
    }
    .read-more:hover {
      text-decoration: underline;
      text-underline-offset: 3px;
    }

    .full-description {
      width: 345px;
      height: 390px;
      font-family: "Mallory-Book", sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      color: #5e6282;
    }
  }
}

@media screen and (min-width: 768px) {
  .card-container {
    width: 335px;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden; // Prevents content from overflowing out of the container
    background-color: rgba(255, 255, 255, 1);
    .image-carousel {
      width: 335px;
      height: 340px;
      overflow: hidden; // Ensures the carousel doesn’t overflow the container
      .office-image-container {
        .office-item-image {
          width: 250%;
          position: relative;
          left: 50%;
          margin-top: -60px;
          transform: translateX(-50%);
        }
      }
    }
  }
}
