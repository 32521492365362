.description-container {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
  background-color: #f0f3f6;
  .description {
    width: auto;
    font-family: "Mallory-Book", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.5px;
    text-align: left;
  }
}

@media screen and (min-width: 768px) {
  .description-container {
    padding: 90px 30px;
  }
}

@media screen and (min-width: 1280px) {
  .description-container {
    padding: 90px 20%;
    .description {
      max-width: 1280px;
    }
  }
}
