.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  box-shadow: 0px 4px 20px 0px #00000033;
  .modal-content {
    width: 90vw;
    max-width: 350px;
    height: auto;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    .image-carousel {
      height: 300px;
      width: 100%;
      overflow: hidden;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .carousal-slider {
        height: 300px;
        position: relative;
        .slick-list {
          height: 300px;
          .slick-track {
            height: 300px;
            .slick-current {
              height: 224px;
            }
          }
        }
        .slick-arrow::before {
          display: none;
        }
        .slick-arrow {
          z-index: 4 !important;
          background: transparent;
          height: 40px;
          width: 40px;
        }
        .slick-prev {
          left: 5px;
        }
        .slick-next {
          right: 5px;
        }
        .office-image-container {
          height: 100%;
          width: 100%;
          .office-item-image {
            width: 100%;
            height: 340px;
            object-fit: cover;
          }
        }
      }
    }
    .content-description {
      padding: 10px 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-height: 350px;
      overflow: scroll;
      margin: 16px 10px 0px 0px;
      .date {
        font-family: "Canela-Regular", sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 24.9px;
        text-align: left;
      }
      .description {
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Mallory", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        color: #5e6282;
      }

      .description::-webkit-scrollbar {
        display: none;
      }

      .close-button {
        width: max-content;
        cursor: pointer;
        font-family: "Mallory", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        color: #5e6282;
      }
    }
    .content-description::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
    }
    .content-description::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border-radius: 5px;
    }
  }
}

@media screen and (min-width: 768px) {
  .modal-wrapper {
    .modal-content {
      max-width: 460px;
    }
  }
}

@media screen and (min-width: 1280px) {
  .modal-wrapper {
    .modal-content {
      max-width: 1000px;
      .content-description {
        height: auto;
        max-height: 350px;
        overflow-x: auto;
        padding-inline: 60px;
        padding-bottom: 16px;
        margin-bottom: 10px;
        .close-button:hover {
          text-decoration: underline;
          text-underline-offset: 3px;
        }
      }
    }
  }
}
