.contact-us-container {
  padding: 50px 20px;
  background-color: var(--white);
  .contact-us-inner-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    .image-section {
      height: 100vw;
      .contact-us-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 16px;
      }
    }
    .contact-us-section {
      border-radius: 16px;
      background: #f0f3f6;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      gap: 60px;
      padding: 30px 40px;
      .contact-us-heading {
        .title {
          font-family: "Canela-Regular", sans-serif;
          font-size: 28px;
          font-weight: 500;
          line-height: 42.28px;
          text-align: left;
          color: #486284;
        }
        .description {
          font-family: "Mallory-Book", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          text-align: left;
          color: #8f8f8f;
          width: 100%;
        }
      }
      .addresses {
        display: flex;
        flex-direction: column;
        gap: 45px;
        .address {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .heading {
            // height: 24px;
            font-family: "Canela-Regular", serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
          }
          .contact-items {
            font-family: "Mallory-Book", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
            .contact-item {
              cursor: default;
              display: block;
              color: #000000;
              text-decoration: none;
              text-indent: -1em;
              padding-left: 1em;
            }
          }
          .contact-item:nth-child(1),
          .contact-item:nth-child(2) {
            cursor: pointer;
          }
          .contact-item:nth-child(3) {
            max-width: 164px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .contact-us-container {
    .contact-us-inner-container {
      .image-section {
        height: 450px;
        .contact-us-image {
          height: 450px;
        }
      }
      .contact-us-section {
        padding: 60px;
        .contact-us-heading {
          .description {
            width: 60%;
          }
        }
        .addresses {
          flex-direction: row;
          gap: 30%;
          .contact-items {
            .contact-item:nth-child(3) {
              max-width: 164px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .contact-us-container {
    display: flex;
    justify-content: center;
    padding: 80px 20px;
    .contact-us-inner-container {
      // width: 100%;
      max-width: 1920px;
      flex-direction: row;
      justify-content: center;
      .image-section {
        width: 90%;
      }
      .contact-us-section {
        width: 100%;
      }
    }
  }
}
