.hero-container {
  position: relative;
  height: 384px;
  .image-container {
    height: 344px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 20px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header-text {
        width: 100%;
        color: #fff;
        font-family: "Canela-Regular", sans-serif;
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 76px;
        letter-spacing: 0.5px;
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%);
      }
      .contact-us {
        cursor: pointer;
        // color: #486284;
        color: white;
        font-family: "Canela-Regular", sans-serif;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 76px;
        letter-spacing: 0.5px;
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        height: 30px;
        display: flex;
        align-items: center;
        // background-color: #ffffff;
        border: 2px solid white;
        padding: 8px 16px;
        border-radius: 2px;
      }
    }
    .title {
      color: #fff;
      font-family: "Canela-Regular";
      text-align: center;
      font-family: "Canela-Regular";
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0.5px;
      margin-top: 50px;
      max-width: 80%;
    }
  }
}

@media screen and (min-width: 768px) {
  .hero-container {
    height: auto;
    .image-container {
      height: 500px;
      background-size: cover;
      display: flex;
      justify-content: flex-start;
      padding: 60px;
      gap: 180px;
      .header {
        .header-text {
          top: 35%;
          font-size: 32px;
        }
        .contact-us {
          border: none;
          background-color: transparent;
          color: #ffffff;
        }
      }
      .title {
        line-height: 48px;
        margin-top: 0px;
        font-size: 52px;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .hero-container {
    height: 800px;
    .image-container {
      height: 750px;
      padding: 50px 0px 0px 0px;
      background-size: cover;
      .header {
        width: 100%;
        max-width: 1920px;
        .header-text {
          width: 400px;
          position: relative;
          font-size: 40px;
          top: 0;
          left: 0;
          transform: none;
        }
        .contact-us {
          width: 320px;
          top: 0;
          left: 0;
          bottom: 0;
          transform: none;
          position: relative;
          font-size: 40px;
        }
        .contact-us:hover {
          text-decoration: underline;
          text-underline-offset: 8px;
          text-decoration-color: white;
          text-decoration-thickness: 1px;
        }
      }
      .title {
        line-height: 76px;
        width: 500px;
        font-size: 52px;
      }
    }
  }
}
