.App {
  text-align: center;
}

p {
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --tango: #fc4d0f;
  --rich-black: #231f20;
  --slate: #24272c;
  --sand: #f0dec1;
  --pewter: #ccc4b5;
  --midnight: #00263f;
  --evergreen: #1c3c34;
  --grey-50: #e3e4ea;
  --white: #ffffff;
  --slate-95: #2f3237;
  --slate-85: #45474c;
  --slate-50: #adacae;
  --slate-15: #dddfe5;
  --slate-10: #eeeff2;
  --global-theme: #fc4d0f;
}
