body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Canela-Bold";
  src: url(./fonts/Canela-Bold-Web.woff) format("woff"),
    url(./fonts/Canela-Bold-Web.woff) format("woff2");
}
@font-face {
  font-family: "Canela-Regular";
  src: url(./fonts/Canela-Regular-Web.woff) format("woff"),
    url(./fonts/Canela-Regular-Web.woff2) format("woff2");
}
@font-face {
  font-family: "Mallory-Book";
  src: url(./fonts/Mallory-Book.woff) format("woff"),
    url(./fonts/Mallory-Book.woff2) format("woff2");
}
@font-face {
  font-family: "Mallory-Light";
  src: url(./fonts/Mallory-Light.woff) format("woff"),
    url(./fonts/Mallory-Light.woff2) format("woff2");
}
@font-face {
  font-family: "Mallory-Medium";
  src: url(./fonts/Mallory-Medium.woff) format("woff"),
    url(./fonts/Mallory-Medium.woff2) format("woff2");
}

@font-face {
  font-family: "Mallory-Medium";
  src: url(./fonts/Mallory-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Mallory-Book";
  src: url(./fonts/Mallory-Book.ttf) format("truetype");
}

@font-face {
  font-family: "Mallory-Bold";
  src: url(./fonts/Mallory-Bold.ttf) format("truetype");
}
