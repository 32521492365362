.no-scroll {
  overflow: hidden;
}

.updates-outer-container {
  background-color: #f0f3f6;
  .updates-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;
    background-color: #f0f3f6;
    padding: 88px 20px 40px;
    position: relative;
    // max-height: 995px;
    // min-height: 904px;
    .updates-title {
      font-family: "Canela-Regular", sans-serif;
      font-size: 48px;
      font-weight: 500;
      line-height: 44px;
      text-align: center;
      color: #486284;
      margin-inline: auto;
    }
    .updates-sub-title {
      font-family: "Mallory-Book", serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      color: #8f8f8f;
      max-width: 100%;
      margin-inline: auto;
      min-height: 90px;
    }
    .updates-cards-container {
      width: calc(100vw - 40px);
      // max-height: 600px;
      overflow-x: scroll;
      padding-bottom: 30px;
      .buttons-container {
        display: none;
        .submenu-carousel-left,
        .submenu-carousel-right {
          cursor: pointer;
          display: block;
          background: none !important;
          border: none !important;
          position: absolute;
          top: 630px;
          left: 20px;
          z-index: 2;
          transform: scale(0.8);
        }
        .submenu-carousel-right {
          left: auto;
          right: 10px;
        }
      }
      .updates-cards {
        width: max-content;
        overflow: visible;
        padding: 0px 10px 10px 10px;
        display: flex;
        gap: 20px;
        align-items: flex-start;
        .card-container {
          width: 285px;
          .image-carousel {
            width: 285px;
            .slick-slider {
              position: relative;
              .slick-dots {
                position: absolute;
                top: 300px;
                left: 50%;
                transform: translateX(-50%);
                width: auto;
                li {
                  background-color: rgba(255, 255, 255, 0.7);
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  margin: 0px 3px;
                  button::before {
                    display: none;
                  }
                }
                .slick-active {
                  background-color: rgba(230, 230, 230, 0.9);
                  width: 30px;
                  border-radius: 30px;
                }
              }
              .slick-arrow {
                display: none;
              }
            }
          }
          .card-content {
            width: 245px;
          }
        }
      }
      // .card-container-overflow {
      //   position: relative;
      //   overflow: visible;
      //   .card-overflow {
      //     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      //     background-color: inherit;
      //   }
      // }
    }
    .updates-cards-container::-webkit-scrollbar {
      background-color: #ccc4b5;
      height: 4px;
    }
    .updates-cards-container::-webkit-scrollbar-thumb {
      background-color: var(--tango);
    }
  }
}

.updates-cards::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 768px) {
  .updates-outer-container {
    .updates-container {
      .updates-cards-container {
        .updates-cards {
          .card-container {
            width: 394px;
            .image-carousel {
              width: 394px;
            }
            .card-content {
              width: 355px;
            }
          }
        }
        .buttons-container {
          display: none;
          .submenu-carousel-left,
          .submenu-carousel-right {
            top: 570px;
          }
          .submenu-carousel-left {
            left: 36px;
          }
          .submenu-carousel-right {
            right: 36px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .updates-outer-container {
    .updates-container {
      overflow-x: hidden;
      padding: 80px 100px;
      align-items: center;
      width: fit-content;
      margin: auto;
      gap: 60px;
      .updates-sub-title {
        max-width: 80%;
        height: auto;
      }
      .updates-cards-container {
        // position: relative;
        max-width: 1240px;
        .updates-cards {
          .card-container {
            width: calc((100vw - 200px - 60px) / 3);
            max-width: 445px;
            .image-carousel {
              width: calc((100vw - 200px - 60px) / 3);
              max-width: 445px;
            }
            .card-content {
              width: calc((100vw - 200px - 160px) / 3);
              max-width: 400px;
            }
          }
        }
        .buttons-container {
          display: block;
          .submenu-carousel-left,
          .submenu-carousel-right {
            display: block;
            height: min-content;
            background: none !important;
            border: none !important;
            position: absolute;
            z-index: 2;
            top: 560px;
          }
          .submenu-carousel-left {
            left: 110px;
            top: 600px;
          }
          .submenu-carousel-right {
            right: 110px;
            top: 600px;
          }
        }
      }
      .updates-cards-container::-webkit-scrollbar {
        display: none;
      }
      .updates-cards-container {
        width: auto;
        display: flex;
        justify-content: flex-start;
        max-width: calc(100vw - 200px);
        padding-bottom: 0px;
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .updates-outer-container {
    .updates-container {
      overflow-x: hidden;
      padding: 80px 100px;
      align-items: center;
      .updates-sub-title {
        max-width: 70%;
      }
      .updates-cards-container {
        // position: relative;
        .buttons-container {
          .submenu-carousel-left,
          .submenu-carousel-right {
            display: block;
            height: min-content;
            background: none !important;
            border: none !important;
            position: absolute;
            z-index: 2;
            top: 560px;
          }
          .submenu-carousel-left {
            left: 150px;
            top: 600px;
          }
          .submenu-carousel-right {
            right: 150px;
            top: 600px;
          }
        }
      }
      .updates-cards-container {
        width: auto;
        display: flex;
        justify-content: flex-start;
        max-width: 1390px;
      }
    }
  }
}
